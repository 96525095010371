<template>
  <div class="game-container">
    <FindElementByPicReviewGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
      :background="`blue`"
    />
  </div>
</template>

<script>
import FindElementByPicReviewGame from "@/components/Course/GamePage/FindElementByPicReviewGame";
export default {
  data() {
    return {
      totalStars: 6,
      questionInfoList: [
        {
          id: 2,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-33-xiao.svg"),
          sound: require("@/assets/audio/L1/5-Words/xiao3.mp3")
        },
        {
          id: 1,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-33-da.svg"),
          sound: require("@/assets/audio/L1/5-Words/da4.mp3")
        },
        {
          id: 4,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-35-bizi.svg"),
          sound: require("@/assets/audio/L1/5-Words/bizi.mp3")
        },
        {
          id: 6,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-37-shou.svg"),
          sound: require("@/assets/audio/L1/5-Words/shou3.mp3")
        },
        {
          id: 5,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-33-erduo.svg"),
          sound: require("@/assets/audio/L1/5-Words/erduo.mp3")
        },
         {
          id: 3,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-33-yanjing.svg"),
          sound: require("@/assets/audio/L1/5-Words/yanjing.mp3")
        },

      ],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            element: "大",
            pinyin: "dà",
            sound: require("@/assets/audio/L1/5-Words/da4.mp3")
          },
          {
            id: 2,
            element: "小",
            pinyin: "xiǎo",
            sound: require("@/assets/audio/L1/5-Words/xiao3.mp3")
          },
          {
            id: 3,
            element: "眼睛",
            pinyin: "yǎnjing",
            sound: require("@/assets/audio/L1/5-Words/yanjing.mp3")
          }
        ],
        rightList: [
          {
            id: 4,
            element: "鼻子",
            pinyin: "bízi",
            sound: require("@/assets/audio/L1/5-Words/bizi.mp3")
          },
          {
            id: 5,
            element: "耳朵",
            pinyin: "ěrduo",
            sound: require("@/assets/audio/L1/5-Words/erduo.mp3")
          },
          {
            id: 6,
            element: "手",
            pinyin: "shǒu",
            sound: require("@/assets/audio/L1/5-Words/shou3.mp3")
          }
        ]
      }
    };
  },
  components: {
    FindElementByPicReviewGame
  }
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>

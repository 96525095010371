<template>
  <div class="game-container">
    <ReviewTrain
      :step="3"
      :vocabularyReviewList="vocabularyReviewList"
      :knowledgeReviewList="knowledgeReviewList"
      :pinyinWordReviewList="pinyinWordReviewList"
      :strokesReviewList="strokesReviewList"
    />
  </div>
</template>

<script>
import ReviewTrain from "@/components/Course/CoursePage/ReviewTrain";
export default {
  data() {
    return {
     
     vocabularyReviewList: [
        [
          {
            pinyin: "yǎnjing",
            hanzi: "眼睛",
          },
          {
            pinyin: "ěrduo",
            hanzi: "耳朵",
          },
          {
            pinyin: "dà",
            hanzi: "大",
          },
          {
            pinyin: "xiǎo",
            hanzi: "小",
          },
          {
            pinyin: "hěn",
            hanzi: "很",
          },
          {
            pinyin: "cháng",
            hanzi: "长",
          },
        ],
        [
          {
            pinyin: "tóufa",
            hanzi: "头发",
          },
          {
            pinyin: "bízi",
            hanzi: "鼻子",
          },
          {
            pinyin: "gèzi",
            hanzi: "个子",
          },
          {
            pinyin: "gāo",
            hanzi: "高",
          },
          {
            pinyin: "méiyǒu",
            hanzi: "没有",
          },
          {
            pinyin: "shǒu",
            hanzi: "手",
          },
        ],
        
      ],
      knowledgeReviewList: [
        [
          {
            pinyin: "dà / xiǎo",
            hanzi: "......大/小",
          },
          {
            pinyin: "chéng dù fù cí hěn",
            hanzi: "程度副词“很”",
          },
          {
            pinyin: "yǒu de fǎn yì cí méiyǒu",
            hanzi: "“有”的反义词“没有”",
          },
         
        ],
      
      ],
      pinyinWordReviewList: [
        [
          {
            vocabulary: "z",
          },
          {
            vocabulary: "c",
          },
          {
            vocabulary: "s",
          },
          {
            vocabulary: "r",
          },
          
        ],
        [
          {
            vocabulary: "zh",
          },
          {
            vocabulary: "ch",
          },
          {
            vocabulary: "sh",
          },
          
        ],
      ],
      strokesReviewList: [
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-34/34-hengzhewan-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-36/36-shuti-white.svg"),
          },
        ],
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-38/38-ti-white.svg"),
          },
         
        ],
      ],
    };
  },
  components: {
    ReviewTrain,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
<template>
  <div class="game-container">
    <RainDropGame
      :optionsList="optionsList"
      :answerList="answerList"
      :bgImage="bgImage"
      @changeAnswerStatus="changeAnswerStatus"
      :starNum="3"
    />
  </div>
</template>

<script>
import RainDropGame from "@/components/Course/GamePage/RainDropGame";
export default {
  data() {
    return {
      bgImage: require("@/assets/img/16-GAMES/G53-drops/background.svg"),
      optionsList: [
        [
          {
            index: 1,
            image: require("@/assets/img/16-GAMES/G53-drops/drop-tou.svg"),
            isAnswer: true,
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-shou.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-xiao.svg"),
          },
        ],
        [
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-bi.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-duo.svg"),
            isAnswer: true,
            index: 2,
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-chang.svg"),
          },
        ],
        [
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-yan.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-shou.svg"),
            
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-chang.svg"),
            isAnswer: true,
            index: 3,
          },
        ],
      ],

      answerList: [
        [
          
          {
            id: 1,
            hanzi: "头",
            pinying: "tóu",
            isChooseCorrect: false,
          },
          {
            hanzi: "发",
            pinying: "fa",
            isChooseCorrect: true,
          },
        ],
        [
          {
            hanzi: "耳",
            pinying: "ěr",
            isChooseCorrect: true,
          },
          {
            id: 2,
            hanzi: "朵",
            pinying: "duo",
            isChooseCorrect: false,
          },
        
        ],
        [
          {
            hanzi: "鼻",
            pinying: "bí",
            isChooseCorrect: true,
          },
          {
            hanzi: "子",
            pinying: "zi",
            isChooseCorrect: true,
          },
          {
            id:3,
            hanzi: "长",
            pinying: "cháng",
            isChooseCorrect: false,
          },
        ],
      ],
    };
  },
  components: {
    RainDropGame,
  },
  methods: {
    changeAnswerStatus(item, index) {
      this.answerList[index].forEach((ele) => {
        if (ele.id === item.index) {
          ele.isChooseCorrect = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>












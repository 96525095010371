<template>
  <div class="lesson-container">
    <DialogPage
      :bgImg="bgImg"
      :pageInfoList="pageInfoList"
      :buttonList="buttonList"
      @clickTheBubble="clickTheBubble"
      :clickIdList="clickIdList"
      :lessonNo="401"
      :titleInfo="titleInfo"
      :isAllScreen="true"
    />
  </div>
</template>

<script>
import DialogPage from "@/components/Course/CoursePage/DialogPage";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "dà, xiǎo, cháng, gāo",
        hanzi: "大、小、长、高",
      },
      clickIdList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      bgImg: require("@/assets/img/03-Backgrounds/level-1-background-blue.svg"),
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/menu-1.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/menu-2.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/menu-3.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
      pageInfoList: [
        {
          index: 1,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/image-1.svg"),
          singlePageList: [
            {
              id: 1,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-da.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 2,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-gao.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 3,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-chang.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 4,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-xiao.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 2,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/image-2.svg"),
          singlePageList: [
            {
              id: 5,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-da.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 6,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-xiao.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 7,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-chang.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 8,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-gao.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 3,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/image-3.svg"),
          singlePageList: [
            {
              id: 9,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-chang.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 10,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-xiao.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 11,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-da.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 12,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-gao.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-40-1/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
      ],
    };
  },
  components: {
    DialogPage,
  },
  methods: {
    clickTheBubble(index, id) {
      this.pageInfoList[index - 1].singlePageList.forEach((item) => {
        if (item.id === id) {
          item.isShowAnser = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-container {
  width: 100%;
  height: 100%;
}
</style>
<template>
  <div class="game-container">
    <RecognitionPinyinGame :optionList="optionList" :imgList="imgList" :isHaveAudio="true"  :limitNumberOfVisibleOptions="3" :isFullScreen="true"/>
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      lessonId: 8,
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/background-z.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/background-c.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/background-s.svg"),
        },
         {
          id: 4,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/background-zh.svg"),
        },
         {
          id: 5,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/background-ch.svg"),
        },
         {
          id: 6,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/background-sh.svg"),
        },
         {
          id: 7,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/background-r.svg"),
        },
      ],

      imgList: [
        {
          id: 1,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/frog-z.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/z.mp3"),

        },
        {
          id: 2,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/frog-c.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/c.mp3"),

        },
        {
          id: 3,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/frog-s.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/s.mp3"),

        },
        {
          id: 4,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/frog-zh.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/zh.mp3"),
        },
        {
          id: 5,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/frog-ch.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ch.mp3"),
        },
        {
          id: 6,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/frog-sh.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/sh.mp3"),
        },
        {
          id: 7,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-05/frog-r.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/r.mp3"),
        },
       
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>












<template>
  <div class="game-container">
    <BingoGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      @changeStatus="changeStatus"
      :lessonType="1"
      :bingoIndexList="[1, 5, 9]"
      :totalStars="totalStars"
    />
  </div>
</template>

<script>
import BingoGame from "@/components/Course/GamePage/BingoGame";
export default {
  data() {
    return {
      totalStars: 5,
      lesson: 16,
      questionInfoList: [
        {
          id: 2,
          pinyin: "tóufa cháng",
          hanzi: "头发长",
        },

        {
          id: 1,
          pinyin: "bízi cháng",
          hanzi: "鼻子长",
        },

        {
          id: 5,
          pinyin: "ěr duo cháng",
          hanzi: "耳朵长",
        },

        {
          id: 6,
          pinyin: "bízi dà",
          hanzi: "鼻子大",
        },

        {
          id: 9,
          pinyin: "yǎnjing xiǎo",
          hanzi: "眼睛小",
        },
      ],
      nameString: ["leftList", "MiddleList", "rightList"],
      asideElementInfo: {
        leftList: [
          {
            id: 2,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-40/7.svg"),
          },
          {
            id: 4,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-40/2.svg"),
          },
          {
            id: 1,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-40/5.svg"),
          },
        ],
        MiddleList: [
          {
            id: 7,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-40/9.svg"),
          },
          {
            id: 5,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-40/6.svg"),
          },
          {
            id: 8,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-40/4.svg"),
          },
        ],
        rightList: [
          {
            id: 9,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-40/3.svg"),
          },
          {
            id: 6,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-40/8.svg"),
          },
          {
            id: 3,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-40/1.svg"),
          },
        ],
      },
    };
  },
  components: {
    BingoGame,
  },
  methods: {
    changeStatus(item) {
      // console.log(item);
      for (let i = 0; i <= 2; i++) {
        this.asideElementInfo[this.nameString[i]].forEach((ele) => {
          if (ele.id === item.id) {
            ele.done = true;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
